<template>
  <div class="collingwood bg-brown">
    <div class="container">
      <b-row class="freight-big-pro-book large-text">
        <b-col>
          <p class="text-blue">
            SET YOUR SIGHTS <br />
            ON THE BEST OF...
          </p>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div class="sb-sights-slider-container">
          <client-only>
            <slick
              ref="sb_sights_slider"
              id="sb-sights-slider"
              :options="sb_amenities_slickOptions"
              class="slick-for"
              v-scroll-reveal="{ origin: 'bottom' }"
            >
              <div fluid v-for="(slide, i) in slider" :key="i">
                <b-row>
                  <b-col cols="10" md="8" class="position-relative">
                    <img :src="slide.img" :alt="slide.alt" class="img-fluid" />
                    <div class="large-text text-overlay">
                      <p class="freight-big-pro-book">
                        {{ slide.heading }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="4" class="center-col">
                    <div>
                      <p class="sub-text acumin-pro-wide-medium text-blue">
                        {{ slide.subheading }}
                      </p>
                      <p class="text-desc mt-3 acumin-pro-wide-light text-blue">
                        {{ slide.desc }}
                      </p>
                      <div class="w-100 mt-3">
                        <img
                          src="/img/back.svg"
                          alt="Previous"
                          class="max-w-55 d-inline-block mr-2"
                          role="button"
                          @click="goPrevSlide()"
                        />
                        <img
                          src="/img/forward.svg"
                          alt="Next"
                          class="max-w-55 d-inline-block"
                          role="button"
                          @click="goNextSlide()"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col cols="12" md="8" class="col-end">
                    <object
                      :data="slide.seal_1"
                      type="image/svg+xml"
                      class="seal"
                    ></object>
                    <object
                    :data="slide.seal_2"
                      type="image/svg+xml"
                      class="seal"
                    ></object>
                    <object
                    :data="slide.seal_3"
                      type="image/svg+xml"
                      class="seal"
                    ></object>
                  </b-col>
                </b-row>
              </div>
            </slick>
          </client-only>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      sliderText: null,
      sb_amenities_slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: false,
        infinite: true,
        lazyLoad: "ondemand",
        vertical: false,
        verticalSwiping: false,
        asNavFor: null,
        autoplay: true,
        fade: true,
        // appendArrows: null,
        // appendDots: null,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
      },
      slider: [
        {
          number: 1,
          img: "/img/collingwood.jpg",
          seal_1: "/img/collingwood/blue_mountain.svg",
          seal_2: "/img/collingwood/scenic_caves.svg",
          seal_3: "/img/collingwood/good_food_stroll.svg",
          heading: "COLLINGWOOD",
          subheading: "TAKE A QUICK DRIVE OR BIKE RIDE",
          desc: "Make the trip to Blue Mountain Village a family event, or to the South Bay Lavender Fields in Collingwood. Visit the Collingwood Harbour or explore its downtown for upscale restaurants, shops, and more.",
        },
        {
          number: 2,
          img: "/img/berrie.jpg",
          seal_1: "/img/collingwood/georgian_mall.svg",
          seal_2: "/img/collingwood/kempenfelt_bay.svg",
          seal_3: "/img/collingwood/spash_on_waterpark.svg",
          heading: "BARRIE",
          subheading: "WHERE EVERY NEED IS THOUGHTFULLY CATERED FOR",
          desc: "You are only moments away from some of the best amenities in Barrie, including waterfront destinations, shopping malls and restaurants.",
        },
      ],
    };
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    goNextSlide(index) {
      this.$refs["sb_sights_slider"].$el.slick.slickNext();
    },
    goPrevSlide(index) {
      this.$refs["sb_sights_slider"].$el.slick.slickPrev();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables";
.collingwood {
  padding: 100px 0px 100px 0px;
  .seal {
    width: 120px;
    @media screen and (min-width: 1500px) {
      width: 140px;
    }
  }
  .center-col {
    display: flex;
    justify-content: center;
    padding: 40px 30px 0px 30px;
    @media screen and (min-width: 1200px) {
      align-items: flex-end;
      justify-content: start;
      max-width: 250px;
      padding: 0px;
    }
  }
  .col-end {
    justify-content: center;
    @media screen and (min-width: 1200px) {
      display: flex;
      justify-content: flex-end;
      padding-top: 2.5rem;
    }
  }
  .sb-sights-slider-container {
    #sb-sights-slider {
      // padding: 100px 0px 50px 0px;
      overflow: visible;
      img {
        padding-top: 2.5rem;
        @media screen and (max-width: 500px) {
          padding-top: 1.5rem;
        }
      }
      .text-overlay {
        overflow: visible;
        color: #fff;
        position: absolute;
        right: -20px;
        top: 0rem;
        margin-left: 10px;
        z-index: 99999;
        @media screen and (max-width: 500px) {
          top: 0rem;
        }
      }
    }
  }
}
</style>
